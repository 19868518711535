<template>
  <div>
    <top-bar/>
    <site-menu/>
    <slot/>
    <footer-bar/>
  </div>
  <cookies-banner/>
</template>

<script>
import TopBar from "@/components/templates/site/TopBar";
import SiteMenu from "@/components/templates/site/SiteMenu";
import FooterBar from "@/components/templates/site/FooterBar";
import CookiesBanner from "@/components/cookies";
export default {
  name: "SiteDefault",
  components: {CookiesBanner, FooterBar, SiteMenu, TopBar}
}
</script>

<style scoped>

</style>