<template>
  <div class="text-4xl font-bold uppercase mx-auto mt-10 text-center">I NOSTRI CORSI</div>
  <div class="container max-w-[1200px] mx-auto flex flex-col items-center lg:items-start justify-between py-8 px-4 gap-5">
    <p class="text-lg">
      L’esigenza di una maggior efficienza nel mondo delle costruzioni, con l’avvento di nuove tecnologie, competenze e normative, richiede oggi un nuovo approccio di pianificazione, progettazione e gestione. Il BIM permette di realizzare la piena integrazione tra la fase progettuale e quella esecutiva. In particolare la progettazione BIM consente di avere una visione d’insieme del sistema edificio coordinando il modello architettonico, strutturale ed impiantistico, evitando interferenze tra le discipline, riducendo al minimo gli imprevisti e gli errori di progettazione ed analizzando con precisione i costi ed i tempi. I nostri corsi BIM forniscono le competenze necessarie per una gestione completa del progetto sia per la progettazione che per la gestione.     </p>
    <div class="flex flex-col md:flex-row w-full gap-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Revit Architettura Base
        </template>
        <template v-slot:description>
          36 ore di corso
        </template>
      </BoxInfoCourse>
      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Revit Architettura Avanzato
        </template>
        <template v-slot:description>
          24 ore di corso
        </template>
      </BoxInfoCourse>
    </div>

    <div class="flex flex-col md:flex-row w-full gap-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Revit Struttura
        </template>
        <template v-slot:description>
          32 ore di corso
        </template>
      </BoxInfoCourse>
    </div>

    <div class="flex flex-col md:flex-row w-full gap-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Revit Impianti MEP Base
        </template>
        <template v-slot:description>
          24 ore di corso
        </template>
      </BoxInfoCourse>

      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Revit Impianti MEP Avanzato
        </template>
        <template v-slot:description>
          16 ore di corso
        </template>
      </BoxInfoCourse>
    </div>


    <div class="flex flex-col md:flex-row w-full gap-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Manager*
        </template>
        <template v-slot:description>
          16 ore di corso
        </template>
      </BoxInfoCourse>

      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Coordinator*
        </template>
        <template v-slot:description>
          16 ore di corso
        </template>
      </BoxInfoCourse>
    </div>

    <div class="flex flex-col md:flex-row w-full gap-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Corso BIM Specialist*
        </template>
        <template v-slot:description>
          16 ore di corso
        </template>
      </BoxInfoCourse>

      <BoxInfoCourse>
        <template v-slot:title>
          Corso CDE Manager* ( Common Data Environment)
        </template>
        <template v-slot:description>
          16 ore di corso
        </template>
      </BoxInfoCourse>
    </div>

    <div class="flex flex-col lg:flex-row items-center pt-0 pb-4 justify-around w-full gap-5 text-borange text-lg">
    * La preparazione all'esame di certificazione richiede 8 ore di corso aggiuntive
    </div>

    <div class="flex flex-col md:flex-row w-full gap-5 my-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Esami per certificazioni BIM
        </template>
        <template v-slot:description>

        </template>
      </BoxInfoCourse>
    </div>



    <div class="flex flex-col md:flex-row w-full gap-5">
      <BoxInfoCourse>
        <template v-slot:title>
          Sconto MagiCAD del 10%
        </template>
        <template v-slot:description>
          <div class="text-center">
            <img src="@/assets/magicad.png" class="h-10 m-auto my-5">
          </div>
          <div>
            Sconto 10% sul prezzo di listino dei Corsi, riservato ai clienti MagiCAD in possesso del Codice Sconto
          </div>
        </template>
      </BoxInfoCourse>
    </div>

    <div class="flex flex-col lg:flex-row items-center pt-0 pb-4 justify-around w-full mb-4 gap-5 text-borange text-lg">
      Tutti i nostri corsi e gli esami di certificazione sono disponibili sia online che in presenza.
      Sará sempre possibile concordare la modalitá di esecuzione dei corsi in funzione delle Vs. necessitá
    </div>
    <div class="flex flex-col lg:flex-row items-stretch gap-5 w-full">
<!--      <div class="flex-1 flex flex-col gap-5">-->
<!--        <div class="flex flex-col w-full gap-5">-->
<!--        <h3 class="font-bold text-center uppercase">SCARICA LA BROCHURE PER CONOSCERE IL PROGRAMMA DELLE LEZIONI, I COSTI E PER ISCRIVERTI AI CORSI</h3>-->
<!--        <a :href="`${baseUrl}BIMINT_brochure_0223.pdf`" class="bg-borange flex-1 text-center text-white  rounded hover:bg-byellow hover:text-black uppercase font-bold p-2">-->
<!--          Scarica brochure-->
<!--        </a>-->
<!--        </div>-->
<!--      </div>-->
      <div class="flex-1 bg-bgray p-5 rounded-xl">
        <form-corsi/>
      </div>
    </div>
  </div>


</template>

<script>
import FormCorsi from "@/components/FormCorsi";
import BoxInfoCourse from "@/components/BoxInfoCourse";

export default {
  name: "SiteCorsi",
  components: {BoxInfoCourse, FormCorsi},
  data() {
    return {
      baseUrl: '',
    }
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_BASE_URL
  }

}
</script>

<style scoped>

</style>