<template>
  <div class="container max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center justify-between py-0 py-16">
    <div class="text-white text-2xl font-semibold flex-1 text-center lg:text-left">Non esitare a contattarci per ricevere maggiori informazioni</div>


    <a href="/contatti" class="bg-borange hover:bg-byellow px-10 py-3 text-white hover:text-black font-semibold my-2">
      CONTATTACI
    </a>
  </div>
</template>

<script>
export default {
  name: "HomeMotto"
}
</script>

<style scoped>

</style>