<template>
  <div class="container max-w-[1200px] mx-auto relative z-10">
    <div class="hidden bg-bdark p-[15px] rounded-3xl md:flex justify-center">
      <div class="flex flex-row items-center gap-16 menu">
        <router-link :to="{name:'Home'}">
          Home
        </router-link>
        <router-link :to="{name:'ChiSiamo'}">
          Chi Siamo
        </router-link>

        <router-link :to="{name:'Strategia'}">
          servizi
        </router-link>

        <router-link :to="{name:'Corsi'}">
          Corsi
        </router-link>

        <router-link :to="{name:'Contatti'}">
          Contatti
        </router-link>

      </div>
    </div>
  </div>



  <button @click="openMenu = true" class="bg-bdark text-white left-1  px-2 py-1 rounded md:hidden ml-1 mb-1">
    <font-awesome-icon icon="fa-solid fa-bars" /> Menù
  </button>

  <div class="z-50 w-full h-full bg-bdark top-0 fixed md:hidden" v-if="openMenu">

    <div class="flex flex-col items-start gap-2 menu p-10 ">
      <div class="w-full text-right">
        <button @click="openMenu = false" class="bg-white text-black px-2 py-1 rounded">
          <font-awesome-icon icon="fa-solid fa-x" />
        </button>
      </div>
      <router-link :to="{name:'Home'}" @click="openMenu = false">
        Home
      </router-link>
      <router-link :to="{name:'ChiSiamo'}" @click="openMenu = false">
        Chi Siamo
      </router-link>

      <router-link :to="{name:'Strategia'}" @click="openMenu = false">
        servizi
      </router-link>

      <router-link :to="{name:'Corsi'}" @click="openMenu = false">
        Corsi
      </router-link>

      <router-link :to="{name:'Contatti'}" @click="openMenu = false">
        Contatti
      </router-link>
    </div>
  </div>

</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
  name: "SiteMenu",
  components: {FontAwesomeIcon},
  data(){
    return{
      openMenu:false,
    }
  }
}
</script>

<style scoped>
.menu a{
  @apply uppercase text-white;
}
.router-link-active{
  @apply text-byellow !important
}
</style>