<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="show">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">

              <div class="mt-3 lg:text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div class="mt-2">
                  <p class="text-sm text-gray-500">

                    Utilizziamo i cookie per assicurarti la migliore esperienza sul nostro sito web.
                    <br>
                    <br>
                    <a href="https://cookiesandyou.com/" class="bg-byellow text-black hover:bg-borange hover:text-white rounded p-2" target="_blank">Per saperne di più...</a>


                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 flex flex-col-reverse sm:flex-row-reverse gap-4">
            <button type="button"
                    @click="setCookies(true)"
                    class="bg-green-800 rounded text-white flex-1 p-2 uppercase">
              Acconsento
            </button>
            <button type="button"
                    @click="setCookies(false)"
                    class="bg-red-800 rounded text-white flex-1 p-2 uppercase">
              Non acconsento
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>


</template>


<script>


export default {
  name: "cookiesBanner",
  components: {},
  data(){
    return {
      show: false,
    }
  },
  mounted() {
    if (!localStorage.cookiesPolicy) {
      this.show = true;
    }
  },
  methods:{
    setCookies(result){
      localStorage.cookiesPolicy = result;
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>