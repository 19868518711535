<template>
  <div class="bg-white">
  <div class="container max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center justify-between py-0 ">
    <div class="flex-1 p-10">
      <div class="text-5xl font-bold uppercase">CHI SIAMO</div>
      <div class="  font-light uppercase my-5">
        BIM INT fornisce consulenza nella modellazione, sviluppo e gestione dei progetti grazie a esperti specializzati
        in diverse discipline ingegneristice e nei processi BIM.
      </div>

      <ul class="font-semibold text-base flex flex-col gap-4">
        <li><font-awesome-icon icon="fa-solid fa-square" class="text-borange mr-3" />Integrazione</li>
        <li><font-awesome-icon icon="fa-solid fa-square" class="text-borange mr-3" />Professionalitá</li>
        <li><font-awesome-icon icon="fa-solid fa-square" class="text-borange mr-3" />Qualitá</li>
      </ul>

      <div class="py-10">
      <a :href="`${baseUrl}flyer-BIMINT.pdf`" class="bg-byellow hover:bg-bgray px-4 py-2 font-semibold">
        SCARICA LA NOSTRA BROCHURE
      </a>
      </div>


    </div>

    <div class="flex-1 hidden lg:block">
      <img src="@/assets/logo_grande.png" class="w-full">
    </div>
  </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
  name: "HomeChiSiamo",
  components: {FontAwesomeIcon},
  data(){
    return {
      baseUrl:process.env.VUE_APP_BASE_URL,
    }
  }
}
</script>

<style scoped>

</style>