<template>
  <SiteDefault>
    <router-view></router-view>
  </SiteDefault>
</template>

<script>

import SiteDefault from "@/templates/SiteDefault";
export default {
  name: 'App',
  components: {
    SiteDefault
  }
}
</script>

<style scoped>

</style>
