<template>
  <div class="home">
    <div class="md:mt-[-35px]">
      <home-carousel/>
    </div>
    <HomeServizi/>
    <div class="md:h-[140px]">
      &nbsp;
    </div>
<!--    <home-corsi/>-->
    <div class="md:h-[140px]">
      &nbsp;
    </div>
    <HomeChiSiamo/>
    <home-motto/>

  </div>
</template>

<script>
import HomeCarousel from "@/components/Home/HomeCarousel";
import HomeServizi from "@/components/Home/HomeServizi";
import HomeChiSiamo from "@/components/Home/HomeChiSiamo";
import HomeMotto from "@/components/Home/HomeMotto";

export default {
  name: "SiteHome",
  components: { HomeMotto, HomeChiSiamo, HomeServizi, HomeCarousel}
}
</script>

<style scoped>
.home {
  @apply bg-bdark
}

@media screen and (min-width: 800px) {
  .home {
    background-image: url('http://ld-wp.template-help.com/wordpress_prod-22996/v1/wp-content/uploads/2019/03/parallax-call-to-action-01.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

</style>