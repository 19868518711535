<template>
  <div class="text-4xl font-bold uppercase mx-auto my-10 text-center">CONTATTI</div>

  <div
      class="container max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between py-8 px-4">
  <iframe class="w-full h-96" src="https://maps.google.com/maps?q=via%20due%20principati%2023&amp;t=m&amp;z=16&amp;output=embed&amp;iwloc=near" title="%3$s" aria-label="%3$s"></iframe>
  </div>
  <div
      class="container max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between py-8 px-4">
    <div class="container-contatti">
      <div class="row">
        <div>
          <font-awesome-icon icon="fa-solid fa-user" class="icon-contatti"/>
        </div>
        <div>
          <div class="title">Italia</div>
          <div class="content">via Due Principati, 23- 83100, Avellino</div>
        </div>
      </div>
      <div class="row">
        <div>
          <font-awesome-icon icon="fa-solid fa-user" class="icon-contatti" />
        </div>
        <div>
          <div class="title">Telefono</div>
          <div class="content">(0039) 380 247 9546; (0046) 707 757800</div>
        </div>
      </div>
      <div class="row">
        <div>
          <font-awesome-icon icon="fa-solid fa-user" class="icon-contatti"/>
        </div>
        <div>
          <div class="title">E-Mail</div>
          <div class="content">E-mail: info@bimint.it</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "SiteContatti",
  components: {FontAwesomeIcon}
}
</script>

<style scoped>
.container-contatti {
  @apply my-8 flex flex-col gap-4
}

.row {
  @apply flex flex-row items-center gap-4 text-xl
}

.title {
  @apply font-bold
}

.icon-contatti{
  @apply text-borange text-4xl
}

.content{
  @apply text-btextgray
}
</style>