<template>
  <div class="border border-bdark flex-1 rounded-lg p-10">
    <div class="text-borange text-center font-bold text-2xl my-4">
      <slot name="title"></slot>
    </div>
    <div class=" text-center font-bold text-lg my-4">
      <slot name="description"></slot>
    </div>
    <div class=" text-center font-bold text-md my-4">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxInfoCourse"
}
</script>

<style scoped>

</style>