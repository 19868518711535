<template>
  <div class="w-full bg-white">

    <div class="container max-w-[1200px] mx-auto py-[25px]">
      <div class="flex flex-col md:flex-row items-center justify-between">
        <div>
          <img src="@/assets/logo_piccolo.png" class="w-[132px]">
        </div>
        <div class="flex flex-col md:flex-row items-center md:divide-x divide-gray-200 ">
          <div class="flex flex-row items-center gap-2 md:pr-7">
            <font-awesome-icon icon="fa-solid fa-map-marker-alt" class="text-borange text-3xl"/>
            <div>Via Due Principati, 19- 83100, Avellino – Italy</div>
          </div>
          <div class="flex flex-row items-center gap-2 md:pl-7">
            <font-awesome-icon icon="fa-solid fa-mobile-alt" class="text-borange text-3xl"/>
            <div>+39 380 247 9546</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "TopBar",
  components: {FontAwesomeIcon}
}
</script>

<style scoped>

</style>