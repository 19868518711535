<template>
  <div class="bg-white">
    <div class="text-4xl font-bold uppercase mx-auto my-10 text-center">CHI SIAMO</div>
    <div class="container max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center justify-between py-0 ">
      <div class="flex-1 hidden lg:block">
        <img src="@/assets/logo_grande.png" class="w-full">
      </div>
      <div class="flex-1 p-10">

        <div class="font-base uppercase my-5 text-center">
          BIM INT fornisce consulenza nella modellazione, sviluppo e gestione dei progetti grazie a esperti specializzati in diverse discipline ingegneristice e nei processi BIM.
        </div>


        <div class="py-10 text-center">
          <a :href="`${baseUrl}flyer-BIMINT.pdf`" class="bg-byellow hover:bg-bgray px-4 py-2 font-semibold">
            SCARICA LA NOSTRA BROCHURE
          </a>
        </div>


      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "SiteChiSiamo",
  data(){
    return {
      baseUrl:process.env.VUE_APP_BASE_URL
    }
  }
}
</script>

<style scoped>

</style>