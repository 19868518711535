<template>

  <div v-if="message" class="text-btextgray">
    <h3 class="font-bold text-2xl">Richiesta inviata!</h3>
    <h3 class="font-semibold text-xl">Verrai contattato il più presto possibile</h3>
    <h3 class="font-semibold text-xl">Grazie!</h3>
  </div>
  <div v-if="!message">
    <div v-if="loader" class="flex flex-row justify-center items-center h-full">
      <font-awesome-icon icon="fa-solid fa-spinner" class="fa-spin text-4xl"/>
    </div>
    <form @submit="handleForm" class="flex flex-col gap-5" v-if="!loader">

      <div class="font-semibold text-center uppercase text-xl">Richiedi maggiorni informazioni</div>

      <div class="row">
        <label>Corso/Attività di interesse</label>

        <vue-multiselect :options="options"
                         placeholder="Seleziona"
                         v-model="optionsSelected"
                         :multiple="true"
        ></vue-multiselect>
      </div>

      <div class="row">
        <label>Nome</label>
        <input type="text" placeholder="Nome" v-model="nome" required>
      </div>

      <div class="row">
        <label>Cognome</label>
        <input type="text" placeholder="Congome" v-model="cognome" required>
      </div>


      <div class="row">
        <label>Email</label>
        <input type="email" placeholder="Indirizzo E-Mail" v-model="email" required>
      </div>

      <div class="row">
        <label>Telefono</label>
        <input type="tel" placeholder="Numero di telefono" v-model="telefono" required>
      </div>

      <div class="row">
        <label>Azienda</label>
        <input type="text" placeholder="azienda" v-model="azienda" required>
      </div>

      <button type="submit"
              class="w-full bg-borange hover:bg-byellow text-white hover:text-black uppercase rounded-lg p-2 font-bold">
        Invia richiesta
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueMultiselect from 'vue-multiselect'
export default {
  name: "FormCorsi",
  components: {FontAwesomeIcon,VueMultiselect},
  data() {
    return {
      nome: '',
      cognome: '',
      email: '',
      corso: '',
      telefono: '',
      azienda: '',
      loader: 0,
      message: false,
      optionsSelected: [],
      options: [
          'Corso BIM Revit Architettura Base',
          'Corso BIM Revit Architettura Avanzato',
          'Corso BIM Revit Struttura',
          'Corso BIM Revit Impianti MEP Base',
          'Corso BIM Revit Impianti MEP Avanzato',
          'Corso BIM Manager',
          'Corso BIM Coordinator',
          'Corso BIM Specialist',
          'Corso CDE Manager',
          'Esame BIM Specialist',
          'Esame BIM Manager',
          'Esame BIM Coordinator',
          'Esame CDE Manager',
          'Consulenza',
          ],
    }
  },
  watch:{
    optionsSelected(newVal){
      this.corso = newVal.join(", ")
    }
  },
  methods: {
    async handleForm(e) {
      e.preventDefault();
      this.loader++;
      await axios.post(process.env.VUE_APP_API_URL + '/send-form', {
        nome: this.nome,
        cognome: this.cognome,
        email: this.email,
        corso: this.corso,
        telefono: this.telefono,
        azienda: this.azienda,
      }).then(e => console.log(e)).catch(e => console.log(e));
      this.loader--;
      this.message = true;
    },

  },
  mounted() {
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.row label {
  @apply font-semibold
}

.row input {
  @apply border border-gray-100 p-1 w-full block rounded
}

.row select {
  @apply border border-gray-100 p-1 w-full block rounded
}
</style>