<template>
  <div class="bg-bgray">
    <div
        class="container max-w-[1200px] mx-auto flex flex-col xl:flex-row items-center xl:items-start justify-between py-8 lg:py-[100px]">

      <div class="w-full flex flex-row justify-around xl:justify-between">
        <div class="card">
          <div class="card-icon">
            <font-awesome-icon icon="fa-solid fa-user-check"/>
          </div>
          <div class="card-title">
            Consulenza
          </div>
          <div class="card-description">
            Supporto alla creazione e sviluppo dei progetti BIM
          </div>
        </div>

        <div class="card">
          <div class="card-icon">
            <font-awesome-icon icon="fa-solid fa-list"/>
          </div>
          <div class="card-title">
            Strategia
          </div>
          <div class="card-description">
            Stesura delle procedure operative
          </div>
        </div>
      </div>

      <div class="w-full flex flex-row justify-around xl:justify-between">
        <div class="card">
          <div class="card-icon">
            <font-awesome-icon icon="fa-solid fa-retweet"/>
          </div>
          <div class="card-title">
            Modellazione
          </div>
          <div class="card-description">
            Modellazione strutturale, impiantistica e architettonica
          </div>
        </div>

        <div class="card">
          <div class="card-icon">
            <font-awesome-icon icon="fa-solid fa-compress-arrows-alt"/>
          </div>
          <div class="card-title">
            Coordinamento
          </div>
          <div class="card-description">
            Gestione, analisi e controllo del progetto
          </div>
        </div>


      </div>
      <div class="w-full flex flex-row justify-around xl:justify-between">
        <div class="card">
          <div class="card-icon">
            <font-awesome-icon icon="fa-solid fa-school"/>
          </div>
          <div class="card-title">
            Formazione
          </div>
          <div class="card-description">
            Erogazione formazione sui software BIM
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "HomeServizi",
  components: {FontAwesomeIcon}
}
</script>

<style scoped>
.card {
  @apply w-[140px] lg:w-[250px] lg:px-[30px] py-[30px] lg:py-0;

}

.card-icon {
  @apply bg-borange flex flex-row items-center justify-center w-[70px] h-[70px] mx-auto text-white text-3xl mb-3
}

.card-title {
  @apply text-lg md:text-xl  lg:text-3xl text-center font-bold my-3;

}

.card-description {
  @apply hidden md:block text-lg text-center font-light;

}
</style>