<template>
  <div class="w-full bg-white">

    <div class="container max-w-[1200px] mx-auto py-[60px]">
      <div class="flex flex-col md:flex-row items-center justify-between">
        <div>
          <img src="@/assets/logo_piccolo.png" class="w-[132px]">
        </div>

        <div class="text-center md:text-left my-2">
          <div class="font-semibold">E-MAIL</div>
          <div class="text-borange mt-2">info@bimint.it</div>
        </div>

        <div class="text-center md:text-left my-2" >
          <div class="font-semibold">TELEFONO</div>
          <div class="text-borange mt-2">+39 380 247 9546</div>
        </div>

        <div class="text-center md:text-left my-2">
          <div class="font-semibold">INDIRIZZO</div>
          <div class="text-borange mt-2">via Due Principati, 19 - 83100, Avellino – Italy</div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar"
}
</script>

<style scoped>

</style>