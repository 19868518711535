<template>
  <div class="bg-white">
    <div class="text-4xl font-bold uppercase mx-auto my-10 text-center">COSA PROPONIAMO</div>

    <div
        class="container max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between py-8 lg:py-[100px]">
      <transition name="card">
        <div class="card">
          <div class="card-icon">
            <font-awesome-icon icon="fa-solid fa-user-check"/>
          </div>
          <div class="card-title">
            Consulenza
          </div>

        </div>
      </transition>

      <div class="card">
        <div class="card-icon">
          <font-awesome-icon icon="fa-solid fa-list"/>
        </div>
        <div class="card-title">
          Strategia
        </div>

      </div>


      <div class="card">
        <div class="card-icon">
          <font-awesome-icon icon="fa-solid fa-retweet"/>
        </div>
        <div class="card-title">
          Modellazione
        </div>

      </div>

      <div class="card">
        <div class="card-icon">
          <font-awesome-icon icon="fa-solid fa-compress-arrows-alt"/>
        </div>
        <div class="card-title">
          Coordinamento
        </div>

      </div>

      <div class="card">
        <div class="card-icon">
          <font-awesome-icon icon="fa-solid fa-solid fa-school"/>
        </div>
        <div class="card-title">
          Formazione
        </div>

      </div>

    </div>
  </div>


  <div class="bg-bgray">
    <div
        class="container max-w-[1200px] mx-auto flex flex-col items-center lg:items-start justify-between py-6 ">
      <div>
        <div class="text-4xl font-bold uppercase mx-auto my-8 text-center">CONSULENZA</div>
        <div class="text-lg mb-6 text-btextgray p-4">
          Assistiamo i clienti nelle fasi di avvio, sviluppo, modellazione e integrazione dei propri progetti nelle
          discipline impiantistiche, strutturali e architettoniche. Forniamo supporto sia a clienti che vogliano
          avviare
          il proprio percorso nel processo BIM, sia che operino già ad un livello avanzato.
        </div>
      </div>

      <div>
        <div class="text-4xl font-bold uppercase mx-auto my-8 text-center">STRATEGIA</div>
        <div class="text-lg mb-6 text-btextgray p-4">
          Forniamo supporto per redigere le procedure BIM più adatte al tipo di progetto e per individuare i software
          da
          utilizzare per la fase di progettazione richiesta. Inoltre, forniamo supporto nei seguenti ambiti:

          <ul>
            <li>modellazione BIM strutturale, impiantistica e architettonica, sia di opere nuove che esistenti;</li>

            <li>coordinamento del progetto BIM: analisi e controllo delle collisioni e gestione delle riunioni di
              coordinamento;
            </li>

            <li>simulazioni BIM per la valutazione economica degli interventi.</li>
          </ul>
        </div>
      </div>

      <div>
        <div class="text-4xl font-bold uppercase mx-auto my-8 text-center">MODELLAZIONE</div>
        <div class="text-lg mb-6 text-btextgray p-4">
          BIMINT ha esperienza nella modellazione BIM in riferimento alla scelta del software più adattato alla fase
          progettuale e alla disciplina interessata (impiantistica, strutturale o architettonica), sia per nuove
          opere,
          sia per opere da ristrutturare.
        </div>
      </div>

      <div>
        <div class="text-4xl font-bold uppercase mx-auto my-8 text-center">COORDINAMENTO</div>
        <div class="text-lg mb-6 text-btextgray p-4">
          Il coordinamento è una delle fasi più importanti della progettazione BIM. Il coordinamento dei modelli e
          l’esperienza di BIMINT permettono di abbattere notevolmente i costi di gestione del progetto.
          L’individuazione e la risoluzione delle problematiche rilevate in fase progettazione dall’analisi delle
          collisioni, consente di
          ottenere un risparmio di tempo in fase di esecuzione dell’opera.
        </div>
      </div>
      <div>
        <div class="text-4xl font-bold uppercase mx-auto my-8 text-center">FORMAZIONE</div>
        <div class="text-lg mb-6 text-btextgray p-4">
          I nostri corsi BIM forniscono le competenze necessarie per una gestione completa del progetto sia per la
          progettazione che per la gestione. Forniamo formazione sia a privati che a societá e copriamo tutte le
          discipline progettuali e le figure manageriali BIM che attualmente sono richieste dal mercato.
        </div>
      </div>
    </div>
  </div>


  <div class="noi">
    <div
        class="container max-w-[1200px] mx-auto flex flex-col items-center lg:items-start justify-between py-20 ">
      <div class="text-3xl font-bold uppercase my-4">Perchè Noi?</div>
      <div class="text-lg mb-6 text-btextgray p-4">
        Ci piacerebbe abitare in un mondo salubre, sereno e piacevole, in cui le opere e le strutture realizzate si
        basino
        su una progettazione all’avanguardia, attenta alla qualità e al comfort di chi le vive e all’ambiente in cui
        sono
        collocate. BIMINT offre competenza, professionalità e sensibilità di esperti, con diversi anni di esperienza
        alle
        spalle, che operano in un contesto interdisciplinare e internazionale.

      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "SiteStrategia"
}
</script>

<style scoped>
.card {
  @apply lg:w-[300px] px-[30px] py-[30px] lg:py-0;

}

.card-icon {
  @apply bg-borange flex flex-row items-center justify-center w-[70px] h-[70px] mx-auto text-white text-3xl mb-3
}

.card-title {
  @apply text-3xl text-center font-bold my-3;

}

.card-description {
  @apply text-lg text-center font-light;

}

.noi {
  background-color: #ffffff;
  background-image: url('http://ld-wp.template-help.com/wordpress_prod-22996/v1/wp-content/uploads/2019/03/arch-bg2.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>