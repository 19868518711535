<template>
  <!-- Slideshow container -->



  <Carousel wrapAround="true" autoplay="5000" @slide-start="handleSlideStart" @slide-end="handleSlideEnd" @init="handleSlideEnd">


    <Slide :key="1">
      <div class="carousel__item" >
        <Transition>
          <div class="absolute top-[50%] text-center w-full z-40 text-xs sm:text-base md:text-2xl lg:text-6xl font-bold text-white" v-if="showText">INTEGRATED DESIGN</div>
        </Transition>

        <img src="@/assets/slide1-scaled.jpg" style="width:100%">
      </div>
    </Slide>

    <Slide :key="2">
      <div class="carousel__item">
        <Transition>
          <div class="absolute top-[50%] text-center w-full z-40 text-xs sm:text-base md:text-2xl lg:text-6xl font-bold text-white" v-if="showText">INTEGRATED DESIGN</div>
        </Transition>
        <img src="@/assets/slide2-scaled.jpg" style="width:100%">
      </div>
    </Slide>

    <Slide :key="3">
      <div class="carousel__item">
        <Transition>
          <div class="absolute top-[50%] text-center w-full z-40 text-xs sm:text-base md:text-2xl lg:text-6xl font-bold text-white" v-if="showText">INTEGRATED DESIGN</div>
        </Transition>
        <img src="@/assets/slide3-scaled.jpg" style="width:100%">
      </div>
    </Slide>

    <Slide :key="4">
      <div class="carousel__item">
        <Transition>
          <div class="absolute top-[50%] text-center w-full z-40 text-xs sm:text-base md:text-2xl lg:text-6xl font-bold text-white" v-if="showText">INTEGRATED DESIGN</div>
        </Transition>
        <img src="@/assets/slide4-scaled.jpg" style="width:100%">
      </div>
    </Slide>
    <template #addons>
      <Navigation />

    </template>
  </Carousel>


</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide,  Navigation } from 'vue3-carousel'

export default {
  name: "HomeCarousel",
  data(){
    return{
      showText:false,
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  methods:{
    handleSlideStart() {
      this.showText = false

    },
    handleSlideEnd() {
      this.showText = true

    },
  }
}
</script>

<style scoped>
.v-leave-active{
  transition: opacity 0.1s ease;
}
.v-enter-active{
  transition: opacity 2.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>