
import { createWebHistory, createRouter } from "vue-router";
import SiteHome from "@/views/SiteHome";
import SiteChiSiamo from "@/views/SiteChiSiamo";
import SiteStrategia from "@/views/SiteStrategia";
import SiteContatti from "@/views/SiteContatti";
import SiteCorsi from "@/views/SiteCorsi";

const routes = [

    {
        path: "/",
        name: "Home",
        component: SiteHome,
    },
    {
        path: "/chi-siamo",
        name: "ChiSiamo",
        component: SiteChiSiamo,
    },

    {
        path: "/strategia",
        name: "Strategia",
        component: SiteStrategia,
    },

    {
        path: "/corsi",
        name: "Corsi",
        component: SiteCorsi,
    },

    {
        path: "/contatti",
        name: "Contatti",
        component: SiteContatti,
    },



   
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
